








































































































































































import { Component, Vue } from "vue-property-decorator";
import { ApiConsultationOrderList } from "@/apis/index";
@Component({})
export default class ConsultationOrder extends Vue {
  //data
  condition: any = {
    start_createdate: "",
    end_createdate: "",
    module: "",
    patient_name: "",
    service_status: "",
  };
  //问诊类别下拉框列表
  typeList = [
    {
      label: "图文",
      value: 501,
    },
    {
      label: "视频",
      value: 502,
    },
    {
      label: "远程",
      value: 503,
    },
  ];
  //订单状态下拉框列表
  stateList = [
    {
      label: "全部",
      value: "",
    },
    {
      label: "待审核",
      value: 211,
    },
    {
      label: "待服务",
      value: 214,
    },
    {
      label: "服务中",
      value: 215,
    },
    {
      label: "完成/取消",
      value: 212,
    },
  ];
  tableData: Array<any> = [];
  pagination = {
    //当前是第几页
    page: 1,
    //分页器当前页显示几条数据
    size: 10,
    //表格的数据总量
    total: 0,
  };
  //hook
  mounted() {
    this.init();
  }
  //methods
  init() {
    this.updateTableData();
  }
  //获取列表数据
  updateTableData() {
    let endTime = this.condition.end_createdate;
    if (endTime) {
      const timeLimit = 24 * 60 * 60 * 1000;
      endTime = endTime + timeLimit - 1;
    }
    const param = {
      start_createdate: this.condition.start_createdate,
      end_createdate: endTime,
      module: this.condition.module,
      patient_name: this.condition.patient_name.trim(),
      service_status: this.condition.service_status,
      cur_page: this.pagination.page,
      page_size: this.pagination.size,
    };
    ApiConsultationOrderList(param).then((res) => {
      if (res.success) {
        this.tableData = res.data;
        this.pagination.total = res.page.total_size;
      }
    });
  }
  //格式化空数据
  dash(data: any) {
    if (data == null || data == "") {
      data = "-";
    }
    return data;
  }
  formatDate(timeStamp: number | string | null) {
    if (timeStamp == null) {
      return "-";
    }
    const time = new Date(timeStamp);
    //年-月-日
    return `${time.getFullYear()}-${this.format(
      time.getMonth() + 1
    )}-${this.format(time.getDate())} ${this.format(
      time.getHours()
    )}:${this.format(time.getMinutes())}:${this.format(time.getSeconds())}`;
  }
  format(date: number | string) {
    date = date.toString();
    return ("0" + date).slice(-2);
  }
  formatType(type: number | null) {
    if (type == null) {
      return "-";
    }
    const value = this.typeList.find((item) => {
      return item.value == type;
    });
    return value == undefined ? "-" : value.label;
  }
  formatState(state: number | null) {
    let result = "";
    if (state == null) {
      result = "-";
      return result;
    }
    const item = this.stateList.find((item) => {
      return item.value == state;
    });
    if (item) {
      result = item.label;
    } else {
      result = "完成/取消 ";
    }
    return result;
  }
  //点击查询按钮触发的事件
  clickSearch() {
    this.updateTableData();
  }
  //点击重置按钮触发的事件
  clickReset() {
    //清空查询条件
    Object.keys(this.condition).map((item: any) => {
      this.condition[item] = "";
    });
    //重置分页器
    this.pagination.page = 1;
    this.pagination.size = 10;
    //重置表格数据
    this.updateTableData();
  }
  //分页器改变页显示数触发函数
  handleSizeChange(val: any) {
    this.pagination.size = val;
    this.updateTableData();
  }
  //分页器改变当前显示页触发函数
  handleCurrentChange(val: any) {
    this.pagination.page = val;
    this.updateTableData();
  }
}
